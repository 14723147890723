<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="customers"
      :items-per-page="25"
      class="elevation-3 mx-lg-10"
      mobile-breakpoint="100"
      calculate-widths
      hide-default-footer
    >
      <template v-slot:top>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
            class="px-8 py-8"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Cari"
              color="purple"
              single-line
              hide-details
              clearable
              @keyup.enter.native="filterData"
              @click:append="filterData"
              @click:clear="filterClear"
            />
          </v-col>
          <v-spacer />
          <v-col
            cols="12"
            sm="6"
            md="4"
            class="px-8 py-8 text-right"
          >
            <v-btn
              color="orange"
              dark
              class="mb-2"
              @click="cancelButtonClick"
            >
              KEMBALI
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <v-tooltip
          bottom
          :color="getTooltipColor(item.profile_verified)"
        >
          <template v-slot:activator="{ on, attrs }">
            <!-- <v-icon
              v-if="item.profile_verified === 1"
              v-bind="attrs"
              class="ml-1"
              color="green darken-2"
              size="18"
              v-on="on"
            >
              mdi-check-circle
            </v-icon> -->
            <v-icon
              v-if="item.profile_verified !== 1"
              v-bind="attrs"
              class="ml-1"
              color="red darken-2"
              size="18"
              v-on="on"
            >
              mdi-close-circle
            </v-icon>
            <span>{{ }} {{ item.fname }} {{ }} {{ item.lname }}</span>
          </template>
          <span>{{ item.profile_verified_string }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.email`]="{ item }">
        <v-tooltip
          bottom
          :color="getTooltipColor(item.email_verified)"
        >
          <template v-slot:activator="{ on, attrs }">
            <!-- <v-icon
              v-if="item.email_verified"
              v-bind="attrs"
              class="ml-1"
              color="green darken-2"
              size="18"
              v-on="on"
            >
              mdi-check-circle
            </v-icon> -->
            <v-icon
              v-if="!item.email_verified"
              v-bind="attrs"
              class="ml-1"
              color="red darken-2"
              size="18"
              v-on="on"
            >
              mdi-close-circle
            </v-icon>
            <span>{{ }} {{ item.email }}</span>
          </template>
          <span v-if="item.email_verified">Terverifikasi</span>
          <span v-else>Belum Verifikasi</span>
        </v-tooltip>
      </template>

      <template v-slot:footer>
        <div class="text-center my-5 py-3">
          <v-pagination
            v-model="page"
            :length="pageCount"
            color="purple"
            @input="onChangePage"
          />
          <span style="font-size: 12px; font-style: italic;">
            Page {{ page }} of {{ pageCount || 1 }}
          </span>
        </div>
        <v-row class="mt-n16">
          <v-col class="text-right pr-8">
            <span style="font-size: 12px; font-style: italic;">
              {{ itemCount + ' dari ' + itemTotal + ' ditampilkan' }}
            </span>
          </v-col>
        </v-row>
      </template>

      <template
        v-if="isAllowVerification"
        v-slot:[`item.actions`]="{ item }"
      >
        <v-btn
          small
          dark
          color="purple"
          class="mr-2"
          @click="verificationButtonClick(item)"
        >
          VERIFIKASI
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    data: () => ({
      search: '',
      headers: [
        { text: 'Type', align: 'start', value: 'type_string' },
        { text: 'Nama', value: 'name' },
        { text: 'Telepon', value: 'phone', sortable: false },
        { text: 'Email', value: 'email', sortable: false },
        { text: 'Nama Perusahaan', value: 'company_name', sortable: false },
        { text: 'Status', value: 'status_string', sortable: false },
        { text: '', value: 'actions', sortable: false },
      ],
      customers: [],
      isAllowVerification: false,
      page: 1,
      pageCount: 0,
      itemCount: 0,
      itemTotal: 0,
    }),

    computed: {
      ...mapState({
        itemsPerPage: state => state.setting.itemsPerPage,
      }),
    },

    watch: {
    },

    created () {
      this.initialize(1, this.itemsPerPage)
    },

    methods: {
      initialize (page, limit, filter) {
        // Check user menus
        const userMenus = localStorage.getItem('userMenus').split(',')
        if (userMenus.includes('CUSTOMER_VERIFICATION')) {
          this.isAllowVerification = true
        }

        const vm = this

        const requestBody = {
          page: page,
          limit: limit,
          searchq: filter,
        }

        axios.post('/t/customer/verification', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.customers = res.data.data.list
            vm.pageCount = res.data.data.pagination.page_total
            vm.itemCount = res.data.data.pagination.item_count
            vm.itemTotal = res.data.data.pagination.item_count_total
          }
        }).catch((e) => {
        })
      },

      getTooltipColor (param) {
        let color = ''
        param === 1 ? color = 'green' : color = 'orange'
        return color
      },

      onChangePage (e) {
        this.initialize(e, this.itemsPerPage)
      },

      verificationButtonClick (item) {
        this.$router.push({ name: 'CustomerVerification', params: { id: item.hash } })
      },

      cancelButtonClick () {
        this.$router.push({ name: 'Customer' })
      },

      filterData () {
        this.initialize(1, this.itemsPerPage, this.search)
      },

      filterClear () {
        this.initialize(1, this.itemsPerPage)
      },
    },
  }
</script>
