var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-3 mx-lg-10",attrs:{"headers":_vm.headers,"items":_vm.customers,"items-per-page":25,"mobile-breakpoint":"100","calculate-widths":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"px-8 py-8",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Cari","color":"purple","single-line":"","hide-details":"","clearable":""},on:{"click:append":_vm.filterData,"click:clear":_vm.filterClear},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filterData($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"px-8 py-8 text-right",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"orange","dark":""},on:{"click":_vm.cancelButtonClick}},[_vm._v(" KEMBALI ")])],1)],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":_vm.getTooltipColor(item.profile_verified)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.profile_verified !== 1)?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"color":"red darken-2","size":"18"}},'v-icon',attrs,false),on),[_vm._v(" mdi-close-circle ")]):_vm._e(),_c('span',[_vm._v(_vm._s()+" "+_vm._s(item.fname)+" "+_vm._s()+" "+_vm._s(item.lname))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.profile_verified_string))])])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":_vm.getTooltipColor(item.email_verified)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!item.email_verified)?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"color":"red darken-2","size":"18"}},'v-icon',attrs,false),on),[_vm._v(" mdi-close-circle ")]):_vm._e(),_c('span',[_vm._v(_vm._s()+" "+_vm._s(item.email))])]}}],null,true)},[(item.email_verified)?_c('span',[_vm._v("Terverifikasi")]):_c('span',[_vm._v("Belum Verifikasi")])])]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"text-center my-5 py-3"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"color":"purple"},on:{"input":_vm.onChangePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c('span',{staticStyle:{"font-size":"12px","font-style":"italic"}},[_vm._v(" Page "+_vm._s(_vm.page)+" of "+_vm._s(_vm.pageCount || 1)+" ")])],1),_c('v-row',{staticClass:"mt-n16"},[_c('v-col',{staticClass:"text-right pr-8"},[_c('span',{staticStyle:{"font-size":"12px","font-style":"italic"}},[_vm._v(" "+_vm._s(_vm.itemCount + ' dari ' + _vm.itemTotal + ' ditampilkan')+" ")])])],1)]},proxy:true},(_vm.isAllowVerification)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","dark":"","color":"purple"},on:{"click":function($event){return _vm.verificationButtonClick(item)}}},[_vm._v(" VERIFIKASI ")])]}}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }